import SubjectScore from "./SubjectScore";
const Assessment = (props) => {
  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }
  
  const data = props.data;
  let totalfirst_grade = 0;
  let totalsecond_grade = 0;
  let totalthird_grade = 0;
  let totalfourth_grade = 0;
  let totalexam_grade = 0;
  let totalfinal_grade = 0;

  for (let i = 0; i < data.length; i++) {
    totalfirst_grade += convertToNumber(data[i].first_grade);
    totalsecond_grade += convertToNumber(data[i].second_grade);
    totalthird_grade += convertToNumber(data[i].third_grade);
    totalfourth_grade += convertToNumber(data[i].fourth_grade);
    totalexam_grade += convertToNumber(data[i].exam_grade);
    totalfinal_grade += convertToNumber(data[i].final_grade);
  }
  
  const assessmentstyle = {
    width: "200px",
    fontSize: "15px",
    fontWeight: "bold"
  };

  const style = {
    width: "30px",
    textAlign: "center",
    height: "8px",
    fontSize: "12px"
  };

  return (
    <>
      <div className="table">
        <table className="tboby table2 table-bordered">
          <thead>
            <tr>
              <th style={style} rowSpan="2">SUBJECTS</th>
              <th style={style} colSpan="3">CONTINUOUS ASSESSMENT</th>
              <th style={style}></th>
              <th style={style}>EXAM</th>
              <th style={style}>TOTAL</th>
              <th style={style}>GRADE</th>
              <th style={style}>REMARKS</th>
            </tr>
            <tr>
              <td style={style}>1st</td>
              <td style={style}>2nd</td>
              <td style={style}>3rd</td>
              <td style={style}>4th</td>
              <td style={style}></td>
              <td style={style}></td>
              <td style={style}></td>
              <td style={style}></td>
            </tr>
          </thead>
          <tbody id="table-body"></tbody>
          <tbody>
            { !data || data.kind === 'not_found' ? null : data.map((dt) => 
              <SubjectScore 
                key={dt.id}
                data={dt}
              />
            )}
            
            <tr>
              <td style={assessmentstyle}>TOTAL</td>
              <td style={style}>{totalfirst_grade == 0 ? null : parseInt(totalfirst_grade)}</td>
              <td style={style}>{totalsecond_grade == 0 ? null : parseInt(totalsecond_grade)}</td>
              <td style={style}>{totalthird_grade == 0 ? null : parseInt(totalthird_grade)}</td>
              <td style={style}>{totalfourth_grade == 0 ? null : parseInt(totalfourth_grade)}</td>
              <td style={style}>{totalexam_grade == 0 ? null : parseInt(totalexam_grade)}</td>
              <td style={style}>{totalfinal_grade == 0 ? null : parseInt(totalfinal_grade)}</td>
              <td style={style}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Assessment;
