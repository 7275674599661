const GradingView = (props) => {
  const data = props.data;
  return (
    <>
      <tr>
        <td>{ data.minimum_mark } ‐ { data.maximum_mark }</td>
        <td>{ data.name }</td>
        <td>{ data.description }</td>
      </tr>     
    </>
  );
};
export default GradingView;