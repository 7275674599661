const SubjectScoreFinal = (props) => {
  const gdata = props.gradingdata;
  
  function convertToNumber(variable) {
    let number = parseFloat(variable); // or Number(variable)
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }

  function getGrade (gdata, total_percentage) {
    for (let g = 0; g<gdata.length; g++){
      if ((total_percentage >= gdata[g].minimum_mark) & (total_percentage <= gdata[g].maximum_mark)) return(gdata[g].name);
    }
  };
  
  function getRemark (gdata, total_percentage) {
    for (let g = 0; g<gdata.length; g++){
      if ((total_percentage >= gdata[g].minimum_mark) & (total_percentage <= gdata[g].maximum_mark)) return(gdata[g].description);
    }
  };

  const subjectName = props.subjectdata;
  const assessment = props.assessmentdata;

  let ft_first_grade = 0;
  let ft_second_grade = 0;
  let ft_third_grade = 0;
  let ft_fourth_grade = 0;
  let ft_exam_grade = 0;
  let ft_final_grade = 0;

  let st_first_grade = 0;
  let st_second_grade = 0;
  let st_third_grade = 0;
  let st_fourth_grade = 0;
  let st_exam_grade = 0;
  let st_final_grade = 0;

  let tt_first_grade = 0;
  let tt_second_grade = 0;
  let tt_third_grade = 0;
  let tt_fourth_grade = 0;
  let tt_exam_grade = 0;
  let tt_final_grade = 0;
  
  let total_final = 0;
  let section_subject_counter = 0;
  let total_percentage = 0;

  if (assessment['first'] == null) {
    ft_first_grade = 0;
    ft_second_grade = 0;
    ft_third_grade = 0;
    ft_fourth_grade = 0;
    ft_exam_grade = 0;
    ft_final_grade = 0;
    total_final += 0;
    //section_subject_counter++;
  } else {
    for (let j = 0; j < assessment['first'].length; j++){
      if (subjectName === assessment['first'][j].subject_name) {
        ft_first_grade = convertToNumber(assessment['first'][j].first_grade);
        ft_second_grade = convertToNumber(assessment['first'][j].second_grade);
        ft_third_grade = convertToNumber(assessment['first'][j].third_grade);
        ft_fourth_grade = convertToNumber(assessment['first'][j].fourth_grade);
        ft_exam_grade = convertToNumber(assessment['first'][j].exam_grade);
        ft_final_grade = convertToNumber(assessment['first'][j].final_grade);
        total_final += convertToNumber(assessment['first'][j].final_grade);
        section_subject_counter++;
      }
    }
  }

  if (assessment['second'] == null) {
    st_first_grade = 0;
    st_second_grade = 0;
    st_third_grade = 0;
    st_fourth_grade = 0;
    st_exam_grade = 0;
    st_final_grade = 0;
    total_final += 0;
    //section_subject_counter++;
  } else {
    for (let k = 0; k < assessment['second'].length; k++){
      if (subjectName === assessment['second'][k].subject_name) {
        st_first_grade = convertToNumber(assessment['second'][k].first_grade);
        st_second_grade = convertToNumber(assessment['second'][k].second_grade);
        st_third_grade = convertToNumber(assessment['second'][k].third_grade);
        st_fourth_grade = convertToNumber(assessment['second'][k].fourth_grade);
        st_exam_grade = convertToNumber(assessment['second'][k].exam_grade);
        st_final_grade = convertToNumber(assessment['second'][k].final_grade);
        total_final += convertToNumber(assessment['second'][k].final_grade);
        section_subject_counter++;
      }
    }
  }

  if (assessment['third'] == null) {
    tt_first_grade = 0;
    tt_second_grade = 0;
    tt_third_grade = 0;
    tt_fourth_grade = 0;
    tt_exam_grade = 0;
    tt_final_grade = 0;
    total_final += 0;
    //section_subject_counter++;
  } else {
    for (let l = 0; l < assessment['third'].length; l++){
      if (subjectName === assessment['third'][l].subject_name){
        tt_first_grade = convertToNumber(assessment['third'][l].first_grade);
        tt_second_grade = convertToNumber(assessment['third'][l].second_grade);
        tt_third_grade = convertToNumber(assessment['third'][l].third_grade);
        tt_fourth_grade = convertToNumber(assessment['third'][l].fourth_grade);
        tt_exam_grade = convertToNumber(assessment['third'][l].exam_grade);
        tt_final_grade = convertToNumber(assessment['third'][l].final_grade);
        total_final += convertToNumber(assessment['third'][l].final_grade);
        section_subject_counter++;
      }
    }
  }

  total_percentage = (total_final / (section_subject_counter * 100)) * 100;

  const grade = getGrade(gdata, total_percentage);
  const remark = getRemark(gdata, total_percentage);
  
  const style = {
    width: "30px",
    textAlign: "center",
    height: "8px",
    fontSize: "12px"
  };
  
  const subjectstyle = {
    width: "200px", 
    fontSize: "15px"
  }
  
  return (
    <>
      <tr>
        <td style={subjectstyle} >{subjectName}</td >
        <td style={style} >{ parseInt(tt_first_grade) }</td>
        <td style={style} >{ parseInt(tt_second_grade) }</td>
        <td style={style} >{ parseInt(tt_third_grade) }</td>
        <td style={style} >{ parseInt(tt_fourth_grade) }</td>
        <td style={style} >{ parseInt(tt_exam_grade) }</td>
        <td style={style} >{ parseInt(ft_final_grade) }</td>
        <td style={style} >{ parseInt(st_final_grade) }</td>
        <td style={style} >{ parseInt(tt_final_grade) }</td>
        <td style={style} >{ parseInt(total_final) }</td>
        <td style={style} >{ parseInt(total_percentage) }</td>
        <td style={style} >{ grade }</td>
        <td style={style} >{ remark }</td>
      </tr>
    </>
  );
};
export default SubjectScoreFinal;