import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
//import '../components/styles/style.css';
import History from '../components/History';
import ReactDOM from 'react-dom';
const App = () => {
  const navigate = useNavigate();
  const [enrollmentid, setEnrollmentid] = useState('');
  const [sessionid, setSessionid] = useState('');
  const [schoolid, setSchoolid] = useState('');
  const [studentid, setStudentid] = useState('');
  const [sectionid, setSectionid] = useState('');
  const [classid, setClassid] = useState('');
  const [sessionname, setSessionName] = useState('');
  const [classname, setClassName] = useState('');
  const [sectionname, setSectionName] = useState('');
  const [scratchcardpn, setScratchcardpn] = useState('');
  const [scratchcardsn, setScratchcardsn] = useState('');
  const [history, setHistory] = useState([]);
  const [shownext, setShownext] = useState(false);
  const [showhistory, setShowhistory] = useState(false);
  const [checkready, setCheckready] = useState(false);
  const [apistartdata, setApistartdata] = useState([]);
  const [resultdata, setResultdata] = useState([]);
  const [loading, setLoading] = useState(false);

  // ... 
  useEffect(() => {
    if (enrollmentid === null || enrollmentid === '' || schoolid === null || schoolid === '' || sessionid === null || sessionid === '' || classid === null || classid === '' || sectionid === null || sectionid === '') {
      setCheckready(false);
    } else {
      setCheckready(true);
    }    
  });

  const checkEnrollment = async (enrollmentid) => {
    console.log("checkEnrollment");
    setLoading(true);
    await fetch('https://early.school:8001/api/resultdirect/start/'+enrollmentid, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => {
      if (!response.ok) {
        alert("unknown student id");
        console.log(response.json());
        setLoading(false);
      }
      return response.json();
    })
    .then((data) => {
      console.log(data.message);
      setLoading(false); 
      if (!data.success){
        alert(data.message);
      } else {
        setStudentid(data.student_id);
        setSchoolid(data.school_id);
        setHistory(data.history);
        setStudentid(data.student_id);
        setApistartdata(data);
        setShownext(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const checkResult = async () => {
    setLoading(true);
    const url = 'https://early.school:8001/api/resultdirect/next/'+enrollmentid;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(
        {
          sessionid: sessionid,
          classid: classid,
          sectionid: sectionid,
          pn: scratchcardpn,
          sn: scratchcardsn
        }
      )
    })
    .then((response) => {
      setLoading(false);
      console.log(response);
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      setLoading(false);
      if (!data.success){
        console.log(data);
        alert(data.desc);
      } else {
        setSchoolid(data.school_id);
        setResultdata(data);
        setShownext(true);
        console.log(data);
        if (!data.final){
          data.enrollmentid = enrollmentid;
          navigate("/result", {
            state: data
          });
        } else {
          navigate("/resultfinal", {
            state: data
          });
        }
      };
    })
    .catch((err) => {
      console.log(err.message);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkEnrollment(enrollmentid);
  };

  const getInitialState = () => {
    const value = "Orange";
    return value;
  };

  const [value, setValue] = useState(getInitialState);

  const handleChange = (e) => {
    var selected = e.target.value
    var hist = selected.split(',');
    setSessionid(hist[0]);
    setClassid(hist[1]);
    setSectionid(hist[2]);
    setSessionName(hist[3]);
    setClassName(hist[4]);
    setSectionName(hist[5]);
    setShowhistory(true);
  };
  
  const classselect = {
    width:"200px"
  };

  return (
    <>
      <div className="container">
      <br></br>
      <center>
        <h2 className=''>
          Earlyschool ResultChecker
        </h2>
        <div className="">
            <form onSubmit={handleSubmit}>
              { shownext ? 
                  <input type="text" className="form-control" value={enrollmentid}
                  onChange={(e) => setEnrollmentid(e.target.value)} disabled='disabled' />
                  : 
                  <input type="text" className="form-control" value={enrollmentid}
                  onChange={(e) => setEnrollmentid(e.target.value)} />
              }
              
              { loading ?
              <>
                <p>finding student info...</p>
              </>
              :
              <>
                { shownext ? 
                  <>
                    <span>Student Name: {apistartdata.profile.first_name} {apistartdata.profile.last_name}</span>
                    <label>Check result for: </label>
                    <select className="form-control" value={value} onChange={handleChange}>
                      <option value={0}>Select record history</option>
                      {history.map((history) => 
                        <History
                          key={history.id}
                          classid={history.class_id}
                          classname={history.class_name}
                          sectionid={history.section_id}
                          sectionname={history.section_name}
                          sessionid={history.session_id}
                          sessionname={history.session_name}
                        />
                      )}
                    </select>
                    { showhistory ? 
                      <>
                      <span>Session: {sessionname}</span><br></br>
                      <span>Class: {classname}</span><br></br>
                      <span>Term: {sectionname}</span><br></br>
                      </>
                      : null }
                      {
                        /*
                        <input type="text" className="form-control" value={scratchcardpn} placeholder='Enter scratch card pin'
                      onChange={(e) => setScratchcardpn(e.target.value)}
                    /> 
                    <input type="text" className="form-control" value={scratchcardsn} placeholder='Enter scratch card serial number'
                      onChange={(e) => setScratchcardsn(e.target.value)}
                    />
                        */
                      }
                    
                    { checkready ? 
                      <button className='btn btn-primary center' type="button" onClick={checkResult} >Check Result</button> 
                      : 
                      <button className='btn btn-primary center' type="button" onClick={checkResult} disabled='disabled' >Check Result</button>
                    }
                  </>
                  : 
                  <button className='btn btn-primary center' type="submit">Check EnrollmentID</button> 
                }
                </>
              }
            </form>
        </div>
      </center>
    </div>
    </>
  );
};
export default App;